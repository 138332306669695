.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    border-radius: 50px !important;
    color: #224066 !important;
    /* border: 2px solid #fff !important; */
    text-transform: capitalize !important;
    font-size: 20px !important;
    margin: 0 10px !important;
}

/* .css-1h9z7r5-MuiButtonBase-root-MuiTab-root::before{
    position: absolute !important;
    background: red !important;
    content: '';
    width: 100% !important;
    height: 100% !important;
    z-index: -;
} */

.tabpanel.css-19kzrtu{
    overflow-y: scroll !important;
    height: 500px !important;
}
/* width */
.tabpanel.css-19kzrtu::-webkit-scrollbar {
    width: 0px;
  }
  
  /* Track */
  .tabpanel.css-19kzrtu::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  .tabpanel.css-19kzrtu::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  .tabpanel.css-19kzrtu::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

.css-1aquho2-MuiTabs-indicator {
    height: 100% !important;
    border-radius: 50px !important;
    z-index: 1 !important;
    background: #224066 !important;
    /* opacity: 0.5 !important; */
}
/* .css-19kzrtu{
    width: 100%;
} */

/* .css-8je8zh-MuiTouchRipple-root{
    background: #000 !important;
    z-index: -1;
} */
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: #fff !important;
    z-index: 2 !important;
}

/* @media only screen and (max-width: 638px) {
    .css-heg063-MuiTabs-flexContainer{
        width: 100%;
        margin: 0 !important;
        overflow-x: scroll;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        flex-direction: column !important;
    }
} */